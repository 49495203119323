import ErrorBoundary from '@/gf/components/ErrorBoundary'
import qs from 'query-string'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import AddProduct from './components/pages/AddProduct'
import Dealers from './components/pages/Dealers'
import Home from './components/pages/Home'
import Import from './components/pages/Import'
import NotFound from './components/pages/NotFound'
import Product from './components/pages/Product'
import Products from './components/pages/Products'
import ConfigProvider from './providers/Config'
import ErrorTrackerProvider from './providers/ErrorTracker'
import GqlClientProvider from './providers/GqlClient'
import MsgrProvider from './providers/Msgr'
import SessionProvider from './providers/Session'

const App = () => (
  <BrowserRouter>
    <ConfigProvider>
      <ErrorTrackerProvider>
        <ErrorBoundary>
          <GqlClientProvider>
            <QueryParamProvider
              adapter={ReactRouter6Adapter}
              options={{
                searchStringToObject: qs.parse,
                objectToSearchString: qs.stringify,
              }}
            >
              <SessionProvider>
                <MsgrProvider>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/products/add" element={<AddProduct />} />
                    <Route path="/products/import" element={<Import />} />
                    <Route path="/products/:id" element={<Product />} />
                    <Route path="/dealers" element={<Dealers />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </MsgrProvider>
              </SessionProvider>
            </QueryParamProvider>
          </GqlClientProvider>
        </ErrorBoundary>
      </ErrorTrackerProvider>
    </ConfigProvider>
  </BrowserRouter>
)

export default App
