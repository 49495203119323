import config from '@/config'
import ConfigContext from '../contexts/Config'

const devScheme = config.devSsl === 'true' ? 'https' : 'http'

const gfBaseUrl = config.canonicalHost
  ? `https://${config.canonicalHost}`
  : `${devScheme}://local.gearflow.com:4000`

const suppliersUrl = config.canonicalHost
  ? `https://suppliers.${config.canonicalHost}`
  : `${devScheme}://suppliers.local.gearflow.com:4000`

const gqlBaseUrl = config.canonicalHost
  ? `https://oems.${config.canonicalHost}`
  : `${devScheme}://oems.local.gearflow.com:4000`

const value = { gfBaseUrl, suppliersUrl, gqlBaseUrl, rollbar: config.rollbar }

const ConfigProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
)

export default ConfigProvider
