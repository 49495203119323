import { forwardRef, InputHTMLAttributes } from 'react'

const DateField = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & { label: string }
>(({ label, ...dateInputProps }, ref) => {
  const id = label.toLowerCase().split(' ').join('_')

  return (
    <div className="mt-2.5">
      <div className="mb-1 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500">
        <label
          htmlFor={id}
          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs text-gray-700"
        >
          {label}
        </label>
        <input
          id={id}
          type="date"
          ref={ref}
          className="block w-full border-0 p-0 text-gray-900 placeholder-gray-700 focus:ring-0 sm:text-sm"
          {...dateInputProps}
        />
      </div>
    </div>
  )
})

export default DateField
